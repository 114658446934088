// NPM Requirements
import React, { Component } from 'react';
import Helmet from 'react-helmet';

class BoeingHome extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Boeing Campus : Everett</title>
        </Helmet>

        <header>
          <div className='Overlay'>
            <h1>Boeing Campus : Everett</h1>
            <p>Choose a Location</p>
          </div>
        </header>

        <div className='HomeInfo'>
          <h2>Tuesday - Thursday</h2>
          <p>9:30am - 12:00pm / 5:45pm - 7:45pm</p>
          <p style={{ 'fontStyle': 'italic' }}>CREDIT/DEBIT CARD & ONLINE ORDERS ONLY</p>
        </div>

        <div className='Location'>
          <a href='https://www.seattlefoodtruck.com/schedule/boeing-campus-everett-location-1'>
            <div className='LocationIcon'>
              <span>40-56</span>
            </div>
            <div className='LocationInfo'>
              <h2>Location 1</h2>
              <p>South Side of Building 40-56</p>
            </div>
          </a>
        </div>

        <div className='Location'>
          <a href='https://www.seattlefoodtruck.com/schedule/boeing-campus-everett-location-3'>
            <div className='LocationIcon'>
              <span>40-26</span>
            </div>
            <div className='LocationInfo'>
              <h2>Location 3</h2>
              <p>Southeast Corner of Building 40-26</p>
            </div>
          </a>
        </div>

        <div className='Location'>
          <a href='https://www.seattlefoodtruck.com/schedule/boeing-campus-everett-location-4'>
            <div className='LocationIcon'>
              <span>40-23</span>
            </div>
            <div className='LocationInfo'>
              <h2>Location 4</h2>
              <p>South Side of Building 40-23</p>
            </div>
          </a>
        </div>

        <div className='Location'>
          <a href='https://www.seattlefoodtruck.com/schedule/boeing-campus-everett-location-5'>
            <div className='LocationIcon'>
              <span className='LocationIcon--smallText'>45-334</span>
            </div>
            <div className='LocationInfo'>
              <h2>Location 5</h2>
              <p>Building 45-334/EMC</p>
            </div>
          </a>
        </div>
      </React.Fragment>
    );
  }
}

export default BoeingHome;
